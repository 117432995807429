.testamonial-card-container {
  border: 2px solid rgb(166, 166, 167);
  color: white;

  text-align: justify;
  width: 60%;
  margin: auto;
  margin-top: 40px;
}
.photo-name {
  display: flex;
  /* background-color: rgb(187, 73, 73); */
  align-items: center;
}
.photo-name p {
  margin-left: 10px;
  font-weight: 500;
  font-size: 25px;
}
.photo-name img {
  width: 70px;
  height: 70px;
  background-color: white;
  border-radius: 50%;
}

@media screen and (max-width:800px) {
    .testamonial-card-container{
        width: 100%;
    } 
}
