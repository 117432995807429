.about-intro-container{
    width:100%;
    overflow: hidden;
    color: rgb(234, 239, 243);
    text-align: justify;
}

/* .about-intro-container .intro-text{
    margin: 40px 0px 0px 120px;
} */
.about-intro-container img{

    margin-top: 40px;
}

.about-intro-container ul{
    margin-left: -30px;
}
@media screen and (max-width:800px) {

    /* .about-intro-container .intro-text{
        margin: 40px auto auto 22px;

    } */
    .about-intro-container img{
        margin-top: 40px;
      /* transform: scale(1.2); */
    }
}
