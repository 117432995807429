.title-container{
    text-align: center;
    color: rgb(235, 235, 235);
  
    text-transform: capitalize;
    /* background-color: white; */
    width: fit-content;
    margin: auto;
    margin-top: 40px;
}
.title-container .lower-border{
    height: 2px !important;
    width: 80%;
    margin: auto;
    background-color: rgb(250, 248, 248,0.7);

    margin-top: -5px;
}