.hover-services-container{
    position: relative;
    margin: 30px auto;
    overflow: hidden;
    cursor: pointer;
    
  }
  .hover-services-container .content-hover{
    color: aliceblue;
    position: absolute;
    top: 0px;
    left: 0px;
    height: 100%;
    width: 30%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgb(76, 77, 78,0.5);
    transition: all ease 0.8s;
  }
  .hover-services-container:hover .content-hover{
    width: 100%;
  }