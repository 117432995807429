.services-home-main-container .intro-text{
  color: whitesmoke;
  margin:40px auto;
  text-align: center;
}
.explore-more-btn{
  background-color: blueviolet;

  font-weight: bold;
  display: flex;
  width: fit-content;
  text-decoration: none;
  padding: 10px 20px;
  margin: 40px auto;
  border: none;
  color: aliceblue;
}
.explore-more-btn:hover{
  opacity: 0.7;
  color: aliceblue;
}
.about-home-main-container{
  margin-top: 0px;
}
.about-home-main-container .about-content{
background-image: url(../images/girlandcameraman.svg);
background-position: top;
background-size: cover;

background-repeat: no-repeat;
margin-top: 100px;
height: 80vh;
}
.about-home-main-container .intro-text{
  color: whitesmoke;
  margin:100px auto;
  width: 65%;
  text-align: center;
}



@media screen and (max-width:800px) {
  .about-home-main-container .about-content{
    height: fit-content;
  }
  .about-home-main-container .intro-text{
    width: fit-content;
  }
  .hover-services-container img{
    height: 100%;
  }
  .hover-services-container{
    height: 150px;
    
  }
}