.blog-card-container{
    color: white;
    position: relative;
    height: 300px !important;
    /* height: 640px;
    width: 640px; */
    /* background-color: white; */
    cursor: pointer;
    margin: 40px auto;
}
.blog-card-container img{
    height: 100%;
    width: 100%;
}
.blog-card-container .content{
    position: absolute !important;
    bottom: 0px;
    left: 0px;
    overflow: hidden;
    display: grid;
    align-items: center;
    /* justify-content: center; */
    width: 100%;
  
    height: 0px;
    background-color: rgb(107, 104, 104,0.5);
    z-index: 20 !important;
   
}

.blog-card-container:hover .content{
    height: 100%;
      padding: 10px;
   
}