.pricing-main-container{
    color: white;
    text-align: center;
}
.pricing-main-container .top-headings{
    margin-top: 70px;
}

.pricing-main-container img{
    width: 600px;
    margin: 40px auto;

}
.pricing-main-container span{
    display: block;
    margin-top: 70px;
  
}