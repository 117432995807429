.hover-card-container{
  
    position: relative;
    /* height: 640px;
    width: 640px; */
    /* background-color: white; */
    cursor: pointer;
}
.hover-card-container .content{
    position: absolute !important;
    color: white !important;
    bottom: 0px;
    left: 0px;
    overflow: hidden;
    display: grid;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 0%;
    background-color: rgb(107, 104, 104,0.5);
    z-index: 20 !important;
   
}

.hover-card-container:hover .content{
    height: 100%;
   
}