.hero-main-container h2 {
  display: inline-block;
}

.hero-main-container {
  padding: 0px;
  margin: 0px;

  color: white;

}
.hero-main-container img {
  height: 500px;
  padding: 0px;
  margin: 0px;
}
.hero-main-container .content {
  position: absolute;
  top: 50px;
  left: 50%;
  transform: translateX(-50%);

  padding: 50px 10px;
}

/* only for first slide */
.hero-main-container .first-slide {
    position: absolute;
    top: 50px;
    left: 10%;
    transform: translateX(0%);
  
    padding: 50px 10px;
  }


.hero-main-container .first-slide+.buttons-container {
    position: absolute;
    bottom: 150px;
    left: 11%;
    transform: translateX(0%);
  }

.hero-main-container .button {
  padding: 15px;
  text-decoration: none;
  /* display: block; */
}

.hero-main-container .buttons-container {
  position: absolute;
  bottom: 150px;
  left: 50%;
  transform: translateX(-50%);
}


.hero-main-container .service-btn,
.contact-btn {
  border: none;
  color: white;
  font-weight: bold;
}
.hero-main-container .service-btn {
  background-color: rgb(50, 131, 207);
  margin-right: 20px;
}
.hero-main-container .contact-btn {
  background-color: rgb(241, 243, 245);

  color: black;
  padding: 15px 30px !important;
  margin-left: 20px;
}
.hero-main-container .button:hover {
  opacity: 0.8;
}
@media screen and (max-width: 600px) {

  .hero-main-container img {
    height: 330px;
  }

  
   .hero-main-container .content {
    width: 100%;  
    text-align: center; 
    position: absolute;
    top: 50px;
    left: 50%;
    transform: translateX(-50%);
  
    padding: 50px 10px;
  }
  

  .hero-main-container .buttons-container {
    width: 100%;
    text-align: center;  
    position: absolute;
    bottom: 80px;
    
  }

  /* only for first slide */
  .hero-main-container .first-slide {
      
      position: absolute;
      top: 50px;
      left: 10%;
      text-align: justify;
      transform: translateX(0%);
    
      padding: 50px 10px;
    }
  
  
  .hero-main-container .first-slide+.buttons-container {
      position: absolute;
      bottom: 100px;
      text-align: justify;
     
      transform: translateX(0%);
    }
  
  
 
}
