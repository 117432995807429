.main-navbar-container {
  background-color: rgb(22, 21, 21);
  color: rgb(255, 255, 255);
  display: flex;
  padding: 25px 5px;
  justify-content: center;
  align-items: center;
}
.navbar-link-container {
  margin-left: auto;
}
.navbar-link-container ul {
  justify-content: end;
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  gap: 10px;
  display: grid;

  text-align: center;
}
.navbar-link-container ul li {
  margin: auto;
  cursor: pointer;
  text-decoration: none;
  text-transform: capitalize;
}
.navbar-link-container ul li:hover{
    border-bottom: 2px solid red;
}

.menu-expand-icon {
  display: none;
}
@media screen and (max-width: 800px) {
  .main-navbar-container {
    justify-content: space-between;
  }
  .navbar-link-container {
    display: none;
  }
  .menu-expand-icon {
    display: inline-block;
  }
}

/* sidebar */
.sidebar-main-container{
    position: fixed;
    z-index: 1000;
    width: 100%;
    margin: 0px;
    left: 0px;
    right: 0px;
    height: 100vh;
    top: 0px;
    text-align: center;
    padding: 0px;
    background-color: rgb(70, 68, 68);
    color:rgb(224, 219, 219);
    transition: all 800ms cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.sidebar-main-container ul{
    margin-top: 80px;
}
.sidebar-main-container ul li{
    padding: 10px;
 

    font-size: larger;
    font-weight: bold;
    text-transform: capitalize;
}
.sidebar-main-container ul li:hover{
    transform: scale(1.1);
}