.main-contact-content-container {
  margin: 50px auto;
  color: rgb(37, 37, 37);
  background-color: white;
  width: 80%;
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: capitalize;
  /* background-image: url(../images/bluesvg.svg);
  background-repeat: no-repeat;
  background-position: top left; */
}

.main-contact-content-container .left-container {
  /* background-color: rgb(66, 66, 66); */
  width: 50%;
  background-image: url(../images/contackbg.svg);
  background-repeat: no-repeat;
  background-position: top right;

}
.main-contact-content-container .left-container .heading {
  font-weight: bold;
  margin-top: 40px;
}
.main-contact-content-container .right-container {
  /* background-color: yellow; */
  width: 50%;
}

.main-contact-content-container .left-container .social-media{
  background-image: url(../images/bluesvg.svg);
  background-repeat: no-repeat;
  background-position: bottom left;
}
.main-contact-content-container .right-container input,
textarea {
  padding: 10px;
  width: 80%;
  margin-top: 10px;
}

.main-contact-content-container .right-container input[type="button"] {
  background-color: rgb(123, 95, 187);
  color: white;
  font-weight: bold;
  outline: none;
  border: none;
}

.main-contact-content-container .right-container input[type="button"]:hover {
  opacity: 0.7;
}

.contact-main-container .social-media span {
  margin-right: 25px;
  font-size: 35px;
  cursor: pointer;
}
.contact-main-container .social-media span:hover {
  font-size: 40px;
}

@media screen and (max-width: 800px) {
  .main-contact-content-container {
    margin: 50px auto;
    color: rgb(37, 37, 37);
    background-color: white;
    width: 99%;
    padding: 10px;
    display: block;
  }
  .main-contact-content-container .left-container {
    width: 100%;
  }
  .main-contact-content-container .right-container {
    width: 100%;
  }

  .main-contact-content-container .right-container input,
  textarea {
    padding: 10px;
    width: 100%;
    margin-top: 10px;
  }
}
