.blogs-main-container .horizontal-blogs-container{
margin-top: 40px;
color: aliceblue;
text-transform: capitalize;
cursor: pointer;
}

.blogs-main-container .horizontal-blogs-container .content{
    margin-top: 20px;
    display: flex;
  
    justify-content: space-between;
}
.more-blogs-container .blogs-card {
    color: aliceblue;
    cursor: pointer;
    
}

.more-blogs-container .blogs-card:hover{
    transform: scale(1.1);
}
.more-blogs-container .blogs-card img{


    height: 250px;
    width: 100%;
}



/* single blogs */
.blog-title-container{
    color: white;
    text-align: center;
}
.blog-title-container span{
    font-size: 2.5rem;
    color: white;
    font-family:fantasy;
}

.left-right-image-container{
    display: flex;
    color: aliceblue;
    justify-content: center;
    align-items: center;
    text-align: justify;
    margin-top: 40px;
}
.img-container{
    width: 45% !important;
    text-align: center;
    

}

.img-container img{
    height: 250px;
    width: 350px;
}
.content-container{
    width: 55%;
    
}
@media screen and (max-width:800px) {
    .left-right-image-container{
        display: block;
    }
    .img-container{
        width: 100% !important;
    }
    .img-container img{
        height: 250px;
        width: 80% ;
    }
    .content-container{
        width: 100% !important;
        
    }
}

.blog-content{
    color: white;
    margin-top: 40px;
}